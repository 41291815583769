@font-face {
  font-family: "Avenir";
  src: url("/src/commom/Assets/fonts/Avenir/Avenir-Black.ttf") format("trutype");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "Avenir", sans-serif !important;
  height: 100vh;
}

.dark-mode {
  /* Primary / Main */
  --primary-100: #f2674a;
  --primary-70: #f4856c;
  --primary-50: #f6a08a;
  --primary-20: #f7c1b4;
  --primary-10: #f9dcd3;

  /* Secondary / Link / Button */
  --secondary-100: #4a7af2;
  --secondary-70: #6c91f4;
  --secondary-50: #8aa6f6;
  --secondary-20: #b4c7f7;
  --secondary-10: #d3ddf9;

  /* Accent / Highlight */
  --accent-100: #dfdd4e;
  --accent-70: #e4e36c;
  --accent-50: #e9e78a;
  --accent-20: #f2f3b4;
  --accent-10: #f8f7d3;

  /* Success / Complete */
  --success-100: #4edf6d;
  --success-70: #70e485;
  --success-50: #8ae99d;
  --success-20: #b4f3c7;
  --success-10: #d3f8e3;

  /* Failure / Warning */
  --failure-100: #ff2a2a;
  --failure-70: #ff5858;
  --failure-50: #ff7a7a;
  --failure-20: #ffb4b4;
  --failure-10: #ffd3d3;

  /* Background */
  --background-100: #ffffff;
  --background-70: #e6e6e6;
  --background-50: #cccccc;
  --background-40: #b3b3b3;
  --background-30: #999999;
  --background-20: #808080;
  --background-10: #666666;

  /* Text */
  --text-100: #141414;
  --text-70: #474747;
  --text-50: #666666;
  --text-20: #a3a3a3;
  --text-10: #cccccc;
}
#root {
  background-color: rgba(0, 0, 0, 0.87);
}
:root {
  /* Primary / Main */
  --primary-100: #f2674a;
  --primary-70: #f4856c;
  --primary-50: #f6a08a;
  --primary-20: #f7c1b4;
  --primary-10: #f9dcd3;

  /* Secondary / Link / Button */
  --secondary-100: #4a7af2;
  --secondary-70: #6c91f4;
  --secondary-50: #8aa6f6;
  --secondary-20: #b4c7f7;
  --secondary-10: #d3ddf9;

  /* Accent / Highlight */
  --accent-100: #dfdd4e;
  --accent-70: #e4e36c;
  --accent-50: #e9e78a;
  --accent-20: #f2f3b4;
  --accent-10: #f8f7d3;

  /* Success / Complete */
  --success-100: #4edf6d;
  --success-70: #70e485;
  --success-50: #8ae99d;
  --success-20: #b4f3c7;
  --success-10: #d3f8e3;

  /* Failure / Warning */
  --failure-100: #ff2a2a;
  --failure-70: #ff5858;
  --failure-50: #ff7a7a;
  --failure-20: #ffb4b4;
  --failure-10: #ffd3d3;

  /* Background */
  --background-100: #141414;
  --background-70: #333333;
  --background-60: #474747;
  --background-50: #666666;
  --background-40: #999999;

  /* Text */
  --text-100: #ffffff;
  --text-70: #cccccc;
  --text-50: #a3a3a3;
  --text-20: #666666;
  --text-10: #474747;
}

.heading {
  color: var(--text-100);
}
.text-white {
  color: var(--text-100);
}
a {
  color: white;
  text-decoration: none;
}
.bg-color {
  background-color: var(--background-100);
}
.text-lightgreen {
  color: var(--success-70);
}
.text-green
{
  color: #4edf6d !important;
}
.text-blue
{
  color: #4A7AF2 !important;
}
.text-red{
  color: #FF2A2A !important;
}
.text-secondary {
  color: var(--text-50) !important;
}

:root {
  height: 100vh;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.p-70 {
  padding: 70px !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.text-end {
  text-align: end !important;
}

.container_new {
  display: flex;
  height: 100vh;
  width: 100vw;
  padding: 0px !important;
  margin: 0;
  /* background: url(images/backgound.png) no-repeat; */
  background-size: 2200px 1500px;
  background-position: center;
}
video {
  position: absolute; /* Position the video absolutely within the container */
  top: 0;
  left: 0;
  height: 100%;       /* Make the video fill the container vertically */
  width: 100%;        /* Make the video fill the container horizontally */
}

input {
  color: var(--text-100) !important;
}
input.svelte-1rq8p9w {
  color: #141414 !important;
}
input::placeholder {
  color: var(--text-100) !important;
}

.logo {
  text-align: start;
  display: flex;
  align-items: center;
}

.text-primary {
  color: var(--text-primary) !important;
}
.text-orange {
  color: var(--text-primary) !important;
}

.Typography {
  text-align: start;
}

.RightSection {
  padding: 40px 120px;
}

& .MuiOutlinedInput-root {
  border-radius: 50px !important;
  max-height: 48px !important;
}
.text-success {
  color: #4edf6d !important;
}
& .MuiInputLabel-root {
  top: -40px !important;
  font-size: 14px !important;
  color: var(--text-grey) !important;
}

& .MuiInputLabel-root.Mui-focused {
  color: var(--primary-100) !important;
}

& .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-100) !important;
}

& .MuiOutlinedInput-notchedOutline {
  border-color: #ffffff33 !important;
}

& input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #141414 inset;
  -webkit-text-fill-color: var(--text-50) !important;
  border-radius: 0% !important;
  caret-color: white;
}

/* &:hover .MuiOutlinedInput-notchedOutline{
    border-color: white !important;
  } */
.dropdown {
  text-align: left;
  /* color: (var(--text-primary)); */
  border-radius: 50px;
}
.main-text {
  color: var(--primary-100) !important;
}
.country {
  font-size: 14px !important;
  top: -40px !important;
  color: var(--text-50) !important;
}

.btn-primary {
  background-color: var(--primary-100) !important;
  color: var(--text-100) !important;
}
.btn-success {
  background-color: var(--success-100) !important;
}
.btn-green{
  background-color: #4EDF6D1A !important;
  color: var(--success-100) !important;
  padding: 5px 10px !important;
  max-width: 100%; /* Prevent the button from exceeding the screen width */
  white-space: nowrap; /* Ensure text stays on one line */
  overflow: hidden; 
    text-overflow: ellipsis !important; 
  display: inline-block !important;
  
}
.btn-blue{
  background-color: #4A7AF2 !important;
  color: var(--text-100) !important;
}
.btn-outline-primary {
  border-color: var(--primary-100) !important;
  color: var(--primary-100) !important;
}
.btn-outline-success {
  border-color: var(--success-100) !important;
  color: var(--success-100) !important;
  font-size: 16px !important;
}
.btn-outline-primary:hover {
  background-color: var(--primary-100) !important;
  color: var(--text-100) !important;
}

.btn {
  text-transform: none !important;
  /* padding: 13px 192px !important; */
  border-radius: 50px !important;
}

.error {
  color: var(--failure-100) !important;
  text-align: left !important;
  margin-top: 10px !important;
  margin-left: 20px !important;
  padding: 0;
}

& .MuiInputBase-root {
  color: var(--text-primary) !important;
  border-color: var(--text-20) !important;
}

.link {
  color: #4a7af2 !important;
  text-decoration: underline;
  font-size: 14px;
}

progress {
  width: 22%;
  height: 5px !important;
  border-radius: 50px !important;
  -webkit-appearance: none;
  appearance: none;
}

progress::-webkit-progress-bar {
  background-color: var(--text-50);
  /* Background color */
  border-radius: 50px !important;
}

progress::-webkit-progress-value {
  background-color: var(--success-100);
  /* Color of the progress bar */
}

.otp {
  background: transparent;
  border: 1px solid var(--text-50);
  outline: none;
  width: 64px;
  height: 48px !important;
  /* padding: 20px 10px; */
  padding-top: 3px;
  border-radius: 50px;
  color: var(--text-100) !important;
  text-align: center;
  font-size: 18px;
}
.pin {
  width: 105px !important;
  height: 48px;
}

.otp:focus {
  border-color: var(--primary-100) !important;
}

.otpgrp {
  gap: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.phone-input-custom .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}

.phone-input-custom .flag-dropdown:hover {
  background-color: transparent !important;
  border: none !important;
}

.phone-input-custom .flag {
  background-color: transparent !important;
  left: 15px;
}

.phone-input-custom .flag:hover {
  background-color: transparent !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}
input:focus {
  border-color: var(--primary-100) !important;
  border-width: 2px !important;
}
input::placeholder {
  color: var(--text-50) !important;
  font-size: medium;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  /* borderColor: submitForm && formik.errors.phoneNumber ? "red" : undefined, */
  height: 48px !important;
  border: 1px solid white !important;
  color: var(--text-50) !important;
  border-color: var(--text-20) !important;
  border-radius: 50px !important;
}
.ReactFlagsSelect-module_selectBtn__19wW7:focus {
  border-color: var(--primary-100) !important;
  border-width: 2px !important;
}
.ReactFlagsSelect-module_selectBtn__19wW7:focus .country {
  color: var(--primary-100) !important;
  /* color: red; */
}
.ReactFlagsSelect-module_selectOptions__3LNBJ {
  max-height: 350px !important;
}
.custom-select {
  /* border-width: 1px; */
  /* border-color: var(--text-100); */
  outline: none;
  /* border-style: solid; */
  border-radius: 50px;
  height: 48px;
  width: 99%;
  font-size: 14x;
  border-color: var(--text-20) !important;
  background: transparent;
}
.border-0 {
  border-radius: 0px !important;
}
.card {
  background-color: #ffffff1a !important ;
  border-radius: 20px !important ;
  border: 1px solid #ffffff33 !important;
  width: fit-content;
}

.noncard {
  background-color: transparent;
  border-radius: none;
  border: none;
  width: fit-content;
}
.focus {
  position: relative;
}
.focus2 {
  position: relative;
}

.focus::after {
  content: '';
  color: var(--primary-100);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 4px;
  border-radius: 50px;
  width: 20px !important; /* Start with width 0 */
  background-color: var(--primary-100); /* Set the color */
  transition: width 0.3s ease; /* Add a transition for smooth effect */
}
.focus2::after{
  content: '';
  color: var(--primary-100);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 4px;
  border-radius: 50px;
  width: 0px; /* Start with width 0 */
  background-color: var(--primary-100); /* Set the color */
  transition: width 0.3s ease; /* Add a transition for smooth effect */
}

.focus2:hover::after{
  width: 20px; /* Set the final width on hover */
}
.focus2:hover{
  color: var(--primary-100) !important;
}
.stock.card {
  min-width: 155px;
  max-width: 155px;
}
.dark-tbl .card {
  background-color: transparent !important;
}

.custom-label .MuiFormControlLabel-label {
  color: white;
  font-size: 16px;
}

& .MuiSvgIcon-root {
  color: var(--text-100);
}
.border-white {
  border-color: var(--background-10) !important;
}

.MuiToggleButton-root {
  background-color: lightgray; /* Default background color */
  color: var(--text-100) !important; /* Default text color */
  border-radius: 20px !important;
  border-color: #808080 !important;
  border: 1px solid;
  padding: 4px 10px !important;
  margin-right: 10px !important;
  text-transform: none !important;
}

/* Hover state styles */
.MuiToggleButton-root:hover,
.MuiToggleButton-root.Mui-selected {
  background-color: #4edf6d33 !important; /* Background color on hover */
  color: var(--success-100) !important;
  border: none !important;
}

/* Selected state styles */

.stock-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}
.stock-icon img {
  object-fit: fill;
  width: 25px;
}
.icon .MuiAvatar-img {
  object-fit: fill !important;
}

.no-border .bRlwPH {
  border: none !important;
}
.no-border .rdt_TableHeadRow {
  border-bottom-color: #4d5154;
  z-index: 99;
  background-color: var(--background-100) !important;
}

.dark-tbl .ejfNUe {
  background-color: rgba(33, 37, 41, 1);
  color: white;
}
/* .yGWof {
  height: 100%;
  width: 100%;
}
.lbQNf {
  overflow: hidden !important;
  text-align: center !important;
}
.gJnpNS {
  background-color: #212529 !important;
} */
.MuiCircularProgress-colorPrimary {
  color: #f37044 !important;
}
.custom-label input {
  color: #4a7af2 !important;
}

.react-datepicker__view-calendar-icon input {
  color: #fff !important;
  background-color: #141414;
  box-shadow: none;
  /* border: 0px; */
  height: 48px;
  border: 1px solid #a3a3a3 !important;
  border-radius: 50px;
  padding: 17px 40px !important;
}
.react-datepicker-wrapper {
  width: 100%;
  /* border: 1px solid #a3a3a3 !important; */
  border-radius: 50px;
}

/* .react-datepicker__input-container {
  padding: 12px;
} */
.MuiMenu-paper {
  background-color: #141414 !important;
  color: white !important;
}
.react-datepicker__view-calendar-icon input {
  /* border: 0px !important; */
  width: 100% !important;
  background-color: transparent !important;
}
.react-datepicker__view-calendar-icon input:focus {
  outline: none !important;
  background-color: transparent !important;
  border: 2px solid var(--primary-100) !important;
  box-shadow: none !important; /* Ensures no shadow on focus */
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  fill: white !important;
  margin: 8px 10px;
}

.buy {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* Track color */
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #4caf50; /* Scrollbar color */
    border-radius: 50px !important;
    /* border: 2px solid #2c2c2c; Adds padding around the thumb */
  }

  /* Firefox scrollbar styling */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: #808080 !important; /* Default border color */
  padding: 0px !important;
}

.MuiSelect-select {
  color: white !important; /* Text color for selected item */
  /* padding: 5px 10px !important; */
  padding-top: 5px !important;
  padding-left: 10px !important;
  padding-bottom: 5px !important;
  font-size: 14px !important;
}
.MuiCardContent-root {
  padding-bottom: 16px !important;
}
.MuiSelect-icon {
  color: var(--text-100) !important;
  /* color: white !important; */
}

.MuiDialog-paper {
  background-color: var(
    --background-100
  ) !important; /* Light blue background */
}
.MuiStepIcon-text {
  color: white !important;
}

.MuiStepIcon-root {
  color: #ffffff1a !important ;
}
.MuiStepIcon-root.Mui-active {
  color: #1976d2 !important ;
}
.MuiStepIcon-root.Mui-completed {
  color: #4edf6d !important ;
}

.carousel-indicators {
  margin-bottom: 0px;
  bottom: -30px;
}
.carousel-indicators button {
  width: 20px;
  height: 4px;
  border: none;
  border-radius: 20px;
  margin: 0px 10px;
  background-color: #ffffff33;
}
.carousel-indicators .active {
  width: 40px;
  height: 4px;
  background-color: #ffffff;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.custom-html-section p {
  color: #a3a3a3;
}

.custom-html-section a {
  color: #4a7af2;
  text-decoration: underline;
}
.custom-html-section ul {
  color: #4a7af2;
  width: 70vw;
}

/* For Chrome, Edge, and Safari */
/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #FFFFFF80;
  border-radius: 20px !important;
}

::-webkit-scrollbar-thumb:hover {

  background: #FFFFFF2A !important;
}

* {
  scrollbar-width: thin;
  scrollbar-arrow-color: transparent !important;
  scrollbar-color: #FFFFFF80 #FFFFFF1A;
} */

/* Scrollbar width */
::-webkit-scrollbar {
  width: 10px !important; /* Adjust the width of the scrollbar */
  max-height: 5px;
}

/* Scrollbar track (the area background) */
::-webkit-scrollbar-track {
  background: #ffffff1a !important; /* Dark background color */
  border-radius: 10px !important; /* Rounded edges */
}

/* Scrollbar handle (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #a3a3a3 !important;
  border-radius: 10px;
}

.carousel-control-prev,
.carousel-control-next {
  top: 50%;
  margin: 0px -20px;
  height: 50px;
}
.rdt_Table,
.rdt_TableHeadRow {
  background-color: transparent !important;
}
.ReactFlagsSelect-module_filterBox__3m8EU input {
  color: #141414 !important;
}

.swal2-container {
  z-index: 999999999;
}
.menuehover:hover{
  background-color: var(--primary-100) !important;
}
.menuehover2:hover{
  border-radius: 20px !important;

}
.menuehover:hover .custom-svg circle {
  fill-opacity: 1; /* Adjust the value as needed */
}
 .Mui-disabled{
  color:grey !important;
 }

 .usermsg{
  background-color: #ffffff1a !important ;
  border-radius: 20px;
  display: flex;
  align-items: center !important;
  padding: 20px;
  margin-left: auto;
  max-width: 975px;
  gap: 10px;
 }
 .usermsg .MuiAvatar-img{
  height: 36px;
  width: 36px;
 }
 .wolfreply{
  background-color: #4A7AF21A;
  border-radius: 20px !important;
  padding: 20px;
  width: fit-content;
  height: fit-content;
  max-width: 1150px !important;
  margin-right: auto;

 }

 .wolfreply .cardinfo{
   margin-top: 20px;
 }
 .scroll-container {
  overflow-y: auto;
  padding-right: 15px !important;
  box-sizing: content-box !important; 
}
.dcard-hover:hover{
  border-color: #4A7AF2 !important;
  cursor: pointer !important;
  background-color: #4A7AF21A !important;
}
.search-hover:hover{
  border-color: #4a7af23a !important;
  cursor: pointer !important;
  background-color: #2c2c2c !important;
}
.gradiant-text{
  font-weight: 700 !important;
  z-index: 2;
  background: linear-gradient(90deg, #FCB118, #F2674A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text; /* Add fallback for other browsers */
  color: transparent; /* Ensure text color is transparent */
  display: inline-block; /* Necessary for Safari rendering */
      
}
.gradient-border {
  position: relative;
  display: inline-block;
  padding: 10px 5px; /* Inner content padding */
  background: linear-gradient(90deg, #F2674A, #FCB118); /* Gradient background */
  border-radius: 10px; /* Rounded corners for outer border */
}

.gradient-border::before {
  content: '';
  position: absolute;
  top: 1px; /* Border thickness */
  left: 1px;
  right: 1px;
  bottom: 1px;
  background: #141414; /* Inner content background */
  border-radius: 10px; /* Match the inner corners */
  z-index: 1;
}

.gradient-border > * {
  position: relative; /* Ensures content is above the pseudo-element */
  z-index: 2;
}
.rdt_TableBody{
  max-height: 540px !important;
  overflow-y: auto  !important;
}
.custom-scrollbar ::-webkit-scrollbar {
  width: 6px !important; /* Width of the scrollbar */
}

.custom-scrollbar ::-webkit-scrollbar-track {
  background: red; /* Background of the scrollbar track */
  border-radius: 6px;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background: #FFFFFF80; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Make the scrollbar thumb rounded */
}

.custom-scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #FFFFFF1A; /* Change color on hover */
}
.rdt_TableRow{
  padding: 30px 0px;
}
.slick-dots {
  display: flex !important; /* Ensures the dots align as per flexbox */
  justify-content: center;
  margin-top: 10px;
}

.slick-dots li {
  margin: 0 40px; /* Space between dots */
}

.slick-dots li button {
  padding: 0;
}

.custom-dot {
  width: 20px; /* Length of the rectangle */
  height: 4px; /* Height of the rectangle */
  border-radius: 20px;
  background-color: #555; /* Inactive color */
  opacity: 0.5; /* Makes the inactive ones slightly transparent */
  transition: background-color 0.5s ease, opacity 0.5s ease;
  margin:0px 20px !important;
}

.slick-dots li.slick-active .custom-dot {
  /* width: 30px; */
  background-color: #fff; /* Active color */
  opacity: 1; /* Fully opaque for active dot */
  margin:0px 20px !important;
}

.slick-slider{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 20px;
}